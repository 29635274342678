.container {
  text-align: center;

  align-items: center;
  justify-content: space-around;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

input:nth-child(1) {
  height: 100px;
}

.container button{ 
  background-color: initial;
  background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px;
  color: #ffffff;
  font-size: 1.5em;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 200px;
  z-index: 9;
  border: 0;
  transition: all  .2s ease-in-out;
  animation: 1s ease-in-out;
}

.container button:hover {
  box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  transform: scale(1.1);
}

